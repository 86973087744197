import { thunkLoadingParams } from 'feature/progressIndicator/progressIndicatorSlice';
import {
	IPaymentDetailsThunkProps,
	IPaymentFeeThunkProps,
	IPaymentRunThunkProps,
	PaymentDetailsType,
} from 'types/Payment';
import { IPayments } from 'types/Payments';
import { exportListToExcel } from 'utils/download';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';
import { ThunkApiType } from 'store/store';

export const getPaymentRunThunk = async (
	arg: thunkLoadingParams & IPaymentRunThunkProps,
	thunkAPI: ThunkApiType,
) => {
	let getPaymentRunURL = `/payments/run?paymentRunId=${encodeURIComponent(
		arg.id.split('|')[0],
	)}&paymentRunDate=${arg.id.split('|')[1]}`;

	const {
		profile: { providerId },
	} = thunkAPI.getState().user;

	if (providerId) {
		try {
			const response = await authFetch.get(getPaymentRunURL);
			return response.data;
		} catch (error: ThunkApiType) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};

export const getPaymentDetailsThunk = async (
	arg: thunkLoadingParams & IPaymentDetailsThunkProps,
	thunkAPI: ThunkApiType,
) => {
	let getPaymentDetailsURL = `/payments/run/group?paymentRunId=${encodeURIComponent(
		arg.paymentRunId.split('|')[0],
	)}&paymentRunDate=${arg.paymentRunId.split('|')[1]}&type=${arg.detailType}`;

	const {
		profile: { providerId },
	} = thunkAPI.getState().user;

	if (providerId) {
		try {
			const response = await authFetch.get(getPaymentDetailsURL);
			return { ...response.data, detailType: arg.detailType };
		} catch (error: ThunkApiType) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};

export const getPaymentAssessmentFeeThunk = async (
	arg: thunkLoadingParams & IPaymentFeeThunkProps,
	thunkAPI: ThunkApiType,
) => {
	let getPaymentAssessmentFeeURL = `/payments/run/claim/${arg.claimId}`;

	const {
		profile: { providerId },
	} = thunkAPI.getState().user;

	if (providerId) {
		try {
			const response = await authFetch.get(getPaymentAssessmentFeeURL);
			return { ...response.data, detailType: PaymentDetailsType.ASSESSMENT_FEE };
		} catch (error: ThunkApiType) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};

export const downloadPaymentClaimThunk = async (
	{ paymentRunId, claimId }: { paymentRunId: string; claimId: string },
	thunkAPI: ThunkApiType,
) => {
	const getDownloadPaymentClaim = `/payments/run/claim/${claimId}/download`;

	try {
		const response = await authFetch.post(
			getDownloadPaymentClaim,
			{},
			{
				responseType: 'blob',
			},
		);
		exportListToExcel(response.data, `Payment_${paymentRunId}_invoice`, 'PDF');
		return true;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const downloadPaymentGroupThunk = async (
	{
		paymentRunId,
		paymentRunDate,
		type,
	}: {
		paymentRunId: string;
		paymentRunDate: string;
		type: 'EOP' | 'GROUP_INVOICE';
	},
	thunkAPI: ThunkApiType,
) => {
	const getDownloadPaymentGroup = `/payments/run/group/download`;

	const body = {
		paymentRunId,
		paymentRunDate,
		type,
	};

	try {
		const response = await authFetch.post(getDownloadPaymentGroup, body, {
			responseType: 'blob',
		});
		exportListToExcel(response.data, `Payment_${paymentRunId}_${type}`, 'PDF');
		return true;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const downloadPaymentThunk = async (
	{ type }: { type: 'PDF' | 'XLSX' },
	thunkAPI: ThunkApiType,
) => {
	const getDownloadPaymentsListURL = `/payments/download`;

	const { paymentsIds, paymentExcludeIds, downloadAll, payments } =
		thunkAPI.getState().payments;
	const { sortingFields, sortingDirections } = thunkAPI.getState().form;

	let listToInclude = [];
	let listToExclude = [];

	listToInclude = payments
		.filter((p: IPayments) =>
			paymentsIds.includes(p.paymentRunId + p.paymentRunDate),
		)
		.map(({ paymentRunDate, paymentRunId }: IPayments) => ({
			paymentRunDate,
			paymentRunId,
		}));

	listToExclude = payments
		.filter((p: IPayments) =>
			paymentExcludeIds.includes(p.paymentRunId + p.paymentRunDate),
		)
		.map(({ paymentRunDate, paymentRunId }: IPayments) => ({
			paymentRunDate,
			paymentRunId,
		}));

	const values = {
		format: type,
		listToInclude: downloadAll ? [] : listToInclude,
		listToExclude,
		sortingFields,
		sortingDirections,
	};

	try {
		const response = await authFetch.post(getDownloadPaymentsListURL, values, {
			responseType: 'blob',
		});
		exportListToExcel(response.data, 'Payments', type);
		return true;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
