import { changePage, getClaims, resetClaims } from 'feature/claims/claimsSlice';
import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	resetForm,
	setCollection,
	setSortingValues,
} from 'feature/form/formSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { AppDispatch, RootState } from 'store/store';
import BoxOverflow from 'styles/box/BoxOverflow';
import { Collections } from 'types/Form';
import ClaimListFooter from './ClaimListFooter.component';
import ClaimsHeader from './ClaimsHeader.component';
import { ClaimsList } from './ClaimsList.component';

const ViewClaims = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { page } = useSelector((store: RootState) => store.claims);
	const {
		inputClaimId,
		inputExternalClaimId,
		inputFirstName,
		inputLastName,
		dateOfService,
		inputClaimStatus,
		sortingDirections,
		sortingFields,
		collection,
	} = useSelector((store: RootState) => store.form);

	const { isDesktop } = useDevice();

	useEffect(() => {
		dispatch(resetForm());
		dispatch(resetClaims());
		dispatch(
			setSortingValues({
				name: 'serviceDate,externalClaimId',
				value: 'DESC,DESC',
			}),
		);
		dispatch(setCollection(Collections.CLAIMS));
		return () => {
			dispatch(resetForm());
			dispatch(resetClaims());
		};
	}, [dispatch]);

	const searchParams = useMemo(
		() => ({
			inputClaimId,
			inputExternalClaimId,
			inputFirstName,
			inputLastName,
			dateOfService,
			inputClaimStatus,
			sortingDirections,
			sortingFields,
		}),
		[
			dateOfService,
			inputClaimId,
			inputExternalClaimId,
			inputClaimStatus,
			inputFirstName,
			inputLastName,
			sortingDirections,
			sortingFields,
		],
	);

	useEffect(() => {
		if (searchParams && collection === Collections.CLAIMS && page) {
			dispatch(getClaims({ showloader: true, limit: '8' }));
		}
	}, [dispatch, isDesktop, page, searchParams, collection]);

	useEffect(() => {
		if (searchParams) dispatch(changePage(1));
	}, [dispatch, searchParams]);

	return (
		<Fragment>
			<BoxOverflow
				mtScrollbar="76px"
				variant="xy"
				sx={{ height: !!isDesktop ? 720 : 490 }}
			>
				<ClaimsHeader />
				<ClaimsList />
			</BoxOverflow>

			<ClaimListFooter />
		</Fragment>
	);
};

export default ViewClaims;
