import { ThunkApiType } from 'store/store';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { IClaim } from 'types/Claim';

export const getClaimDetailsThunk = async (
	claimid: string,
	thunkAPI: ThunkApiType,
) => {
	let getClaimDetailsURL = `/claim/${claimid}`;

	try {
		const response = await authFetch.get(getClaimDetailsURL);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getPrescriptionsThunk = async (
	_: object,
	thunkAPI: ThunkApiType,
) => {
	const { memberSubscriptionId } = thunkAPI.getState().claim.claimData;
	try {
		const response = await authFetch.get(
			`/prescription?subscriptionId=${memberSubscriptionId}`,
		);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const saveClaimThunk = async (
	input: { switchPage: boolean; redirect: boolean },
	thunkAPI: ThunkApiType,
) => {
	let formData = thunkAPI.getState().claim.claimData;
	if (input.switchPage) {
		formData = { ...formData, step: formData.step ? formData.step + 1 : 1 };
	}
	try {
		const response = await authFetch.post('/claim', formData);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const calculateEstimatesThunk = async (
	formData: IClaim,
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.post('/claim/calculateCost', formData);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const submitClaimThunk = async (
	formData: IClaim,
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.post('/claim/submit', formData);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getDoctorTypesThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.get('/doctor/typologies');
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
