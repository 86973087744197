import { thunkLoadingParams } from 'feature/progressIndicator/progressIndicatorSlice';
import { ThunkApiType } from 'store/store';
import { IAttachment } from 'types/Attachment';
import authFetch, { checkForUnauthorizedResponse } from 'utils/axios';

export const getExistingPrescriptionsListThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	try {
		const { memberEyemedUuid } = thunkAPI.getState().member.member;

		const response = await authFetch.get(
			`/attachments/prescription/${memberEyemedUuid}/portal`,
		);

		return response.data as IAttachment[];
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const gerAttachmentPreview = async (
	_: thunkLoadingParams,
	thunkAPI: ThunkApiType,
) => {
	const {
		selectedAttachment: { resourceUrl },
	} = thunkAPI.getState().attachments;

	if (resourceUrl) {
		const getDocumentPreviewURL = resourceUrl;
		try {
			const response = await authFetch.get(getDocumentPreviewURL, {
				responseType: 'blob',
			});
			return URL.createObjectURL(response.data);
		} catch (error: ThunkApiType) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};
