import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { DocType } from 'types/Upload';
import { setUploadPercentage } from './uploadSlice';
import { thunkLoadingParams } from 'feature/progressIndicator/progressIndicatorSlice';
import { camelcaseToUnderscore } from 'utils/utils';
import { ThunkApiType } from 'store/store';

export const uploadDocumentThunk = async (
	arg: thunkLoadingParams & { docType: DocType },
	thunkAPI: ThunkApiType,
) => {
	const {
		claimData: { memberSubscriptionId },
	} = thunkAPI.getState().claim;
	const { file } = thunkAPI.getState().upload;
	const sendFileURL = `/attachments/${camelcaseToUnderscore(
		arg.docType,
	)}?subscriptionId=${memberSubscriptionId}`;

	let formData = new FormData();
	formData.append('image', file);

	try {
		const response = await authFetch.post(sendFileURL, formData, {
			signal: thunkAPI.signal,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			onUploadProgress: upload => {
				let progress = Math.round((100 * upload.loaded) / (upload?.total || 0));
				thunkAPI.dispatch(setUploadPercentage(progress));
			},
		});
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const deleteDocumentThunk = async (
	{ id, docType }: { id: number; docType: DocType },
	thunkAPI: ThunkApiType,
) => {
	const deleteFileURL = `/attachments/${camelcaseToUnderscore(docType)}/${id}`;

	try {
		const response = await authFetch.delete(deleteFileURL);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const editDocumentNameThunk = async (
	{
		docType,
		fileName,
		documentId,
	}: { fileName: string; documentId: number; docType: DocType },
	thunkAPI: ThunkApiType,
) => {
	const sendFileURL = `/attachments/${camelcaseToUnderscore(
		docType,
	)}/${documentId}`;

	try {
		const response = await authFetch.put(sendFileURL, {
			fileName,
			[`${docType}Id`]: documentId,
		});
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
