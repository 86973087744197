import { combineReducers, configureStore } from '@reduxjs/toolkit';

import dialogReducer from 'feature/hooks/dialogSlice';
import locationHookSlice from 'feature/hooks/locationSlice';
import memberReducer from 'feature/members/memberSlice';
import membersReducer from 'feature/members/membersSlice';
import progressIndicatorReducer from 'feature/progressIndicator/progressIndicatorSlice';
import attachmentsReducer from '../feature/attachments/attachmentsSlice';
import claimReducer from '../feature/claims/claimSlice';
import claimsReducer from '../feature/claims/claimsSlice';
import contactUsReducer from '../feature/contactUs/contactUsSlice';
import cookiesReducer from '../feature/cookies/cookiesSlice';
import documentationReducer from '../feature/documents/documentsSlice';
import footerReducer from '../feature/footer/footerSlice';
import formReducer from '../feature/form/formSlice';
import uploadFileDialogSlice from '../feature/hooks/uploadFileDialogSlice';
import limitationsReducer from '../feature/limitations/limitationsSlice';
import locationsReducers from '../feature/location/locationSlice';
import locationInformationReducer from '../feature/locationInformation/locationInformationSlice';
import paymentReducer from '../feature/payments/paymentSlice';
import paymentsReducer from '../feature/payments/paymentsSlice';
import searchReducer from '../feature/search/searchSlice';
import textsLocalesReducer from '../feature/textsLocales/textsLocalesSlice';
import uploadReducer from '../feature/upload/uploadSlice';
import userReducer from '../feature/user/userSlice';
import healthCheckReducer from '../feature/utils/checkConnectionSlice';
import downloaderReducer from '../feature/utils/downloaderSlice';

const rootReducer = {
	user: userReducer,
	progressIndicator: progressIndicatorReducer,
	location: locationsReducers,
	search: searchReducer,
	hooks: combineReducers({
		dialog: dialogReducer,
		location: locationHookSlice,
		uploadFileDialog: uploadFileDialogSlice,
	}),
	documentation: documentationReducer,
	limitations: limitationsReducer,
	claims: claimsReducer,
	claim: claimReducer,
	members: membersReducer,
	member: memberReducer,
	form: formReducer,
	upload: uploadReducer,
	payments: paymentsReducer,
	payment: paymentReducer,
	locationInformation: locationInformationReducer,
	footer: footerReducer,
	downloader: downloaderReducer,
	cookies: cookiesReducer,
	textsLocales: textsLocalesReducer,
	contactUs: contactUsReducer,
	healthCheck: healthCheckReducer,
	attachments: attachmentsReducer,
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkApiType = any;
