import { ReactComponent as UploadIcon } from 'assett/icons/upload_arrow_icon_bold.svg';
import { ReactComponent as DisabledUploadIcon } from 'assett/icons/upload_arrow_icon_bold_disabled.svg';
import {
	getExistingPrescriptionsList,
	resetAttachmentsState,
	setSelectedAttachment,
} from 'feature/attachments/attachmentsSlice';
import { setClaimPrescription } from 'feature/claims/claimSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { Box, Button, DialogDesktop } from 'styles';
import { useTranslate } from 'stylesHooks';
import { IAttachment } from 'types/Attachment';
import { TRANSLATION_KEYS } from 'utils/constants';
import PrescriptionListItem, { DocSelected } from './PrescriptionListItem';
import PrescriptionPreview from './PrescriptionPreview';

const SelectPrescription = ({ disabled }: { disabled: boolean }) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { existingPrescriptionsList, selectedAttachment } = useSelector(
		(state: RootState) => state.attachments,
	);

	const [openDialog, setOpenDialog] = useState(false);
	const [selectedPrescription, setSelectedPrescription] =
		useState<DocSelected | null>(null);

	const handleClick = () => {
		setOpenDialog(true);
	};

	const handleClose = () => {
		setOpenDialog(false);
		setSelectedPrescription(null);
		dispatch(resetAttachmentsState());
	};

	const handleSelectPrescription = (docSelected: DocSelected) => {
		setSelectedPrescription(docSelected);
	};

	const handlePreviewPrescription = (doc: IAttachment) => {
		setSelectedPrescription({ id: doc.id, fileName: doc.fileName });
		dispatch(setSelectedAttachment(doc));
	};

	const handleConfirmPrescription = () => {
		dispatch(
			setClaimPrescription({
				id: selectedPrescription?.id,
				fileName: selectedPrescription?.fileName,
			}),
		);
		handleClose();
	};

	useEffect(() => {
		if (openDialog) {
			dispatch(getExistingPrescriptionsList(''));
		}
	}, [dispatch, openDialog]);

	return (
		<>
			<Button
				key={disabled ? 1 : 2}
				variant="underline"
				sx={{ marginBottom: '20px', marginTop: '10px', width: 'fit-content' }}
				onClick={handleClick}
				disabled={disabled}
				startIcon={
					disabled ? (
						<DisabledUploadIcon width={24} height={24} />
					) : (
						<UploadIcon width={24} height={24} />
					)
				}
			>
				{tClientLabels('newClaim.uploadPrescription')}
			</Button>

			<DialogDesktop
				close={<></>}
				scroll="paper"
				fullWidth
				PaperProps={{
					sx: {
						minHeight: {
							xl: '865px',
							lg: '865px',
							md: '658px',
							sm: '658px',
							xs: '658px',
						},
						maxHeight: {
							xl: '865px',
							lg: '865px',
							md: '658px',
							sm: '658px',
							xs: '658px',
						},
						minWidth: {
							xl: '840px',
							lg: '840px',
							md: '768px',
							sm: '768px',
							xs: '768px',
						},
					},
				}}
				actions={
					<>
						<Button variant="outlined" onClick={handleClose}>
							{tClientLabels('commons.buttons.cancel')}
						</Button>
						<Button
							variant="contained"
							disabled={!selectedPrescription}
							onClick={handleConfirmPrescription}
						>
							{tClientLabels('dialog.buttons.upload')}
						</Button>
					</>
				}
				open={openDialog}
				title={tClientLabels(`newClaim.upload.selectSavedPrescriptionDialogTitle`)}
			>
				{selectedAttachment && <PrescriptionPreview doc={selectedAttachment} />}
				{!selectedAttachment && (
					<Box
						component="ul"
						sx={{
							maxHeight: {
								xl: '715px',
								lg: '715px',
								md: '550px',
								sm: '550px',
								xs: '550px',
							},

							overflowY: 'auto',
							display: 'flex',
							flexDirection: 'column',
							gap: '15px',
							p: {
								xl: '32px',
								lg: '32px',
								md: '20px',
								sm: '20px',
								xs: '20px',
							},
							m: 0,
						}}
					>
						{existingPrescriptionsList.map(doc => (
							<PrescriptionListItem
								key={doc.id}
								doc={doc}
								selectedPrescription={selectedPrescription}
								handleSelectPrescription={handleSelectPrescription}
								handlePreviewPrescription={handlePreviewPrescription}
							/>
						))}
					</Box>
				)}
			</DialogDesktop>
		</>
	);
};

export default SelectPrescription;
