import { useTranslate } from 'stylesHooks';
import { Dispatch, FC, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Stack, Switch, Typography } from 'styles';
import palette from 'styles/theme/Palette';
import { IPrescription, PRESCRIPTION_DEFAULT } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import PrescriptionTable from './PrescriptionTable';

type prescriptionProps = {
	presFilled: boolean;
	label: string;
	data: IPrescription;
	setData?: (arg0: IPrescription) => void;
	editable?: boolean;
	checkFields: boolean;
	setRequiredFilled: Dispatch<SetStateAction<boolean>>;
	isOldPrescription?: boolean;
};

const Prescription: FC<prescriptionProps> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const {
		label,
		data,
		setData = () => {},
		editable = true,
		checkFields,
		setRequiredFilled,
		presFilled,
		isOldPrescription,
	} = props;
	const { claimData } = useSelector((store: RootState) => store.claim);

	// if data is null or undefined for any kind of reasons we use the default values
	let defaultData = data;
	if (!defaultData) {
		defaultData = PRESCRIPTION_DEFAULT;
	}

	let isContactOnly = false;
	if (claimData?.materials) {
		isContactOnly = claimData.materials === 5;
	} else if (
		claimData?.servicesList &&
		claimData.servicesList.filter(el => el !== 'Exam').length === 1
	) {
		isContactOnly = !!claimData.servicesList.find(el => el === 'Contacts');
	}

	return (
		<>
			<Stack gap={1.25} width={'98%'}>
				{label && (
					<Typography variant="bodyLink">{tClientLabels(label)}</Typography>
				)}
				<Stack gap={3.75} sx={{ p: 6, backgroundColor: palette.white.main }}>
					<Stack
						direction={{ sm: 'column', lg: 'row' }}
						gap={{ sm: 3.75, lg: 0 }}
						justifyContent={{ sm: 'left', lg: 'space-between' }}
					>
						<Stack direction="column" gap={1} width={549}>
							<Typography variant="title6">
								{tClientLabels('newClaim.prescription.prescription')}
							</Typography>
							<Typography variant="body" color={palette.grey1.main}>
								{tClientLabels('newClaim.prescription.enterFields')}
							</Typography>
						</Stack>
						{!isContactOnly && !isOldPrescription && (
							<Stack direction="row" gap={4}>
								<Switch
									label={tClientLabels('newClaim.prescription.ava')}
									disabled={!editable}
									checked={Boolean(defaultData.ava)}
									onChange={(_, checked) => {
										setData({ ...defaultData, ava: Number(checked) });
									}}
								/>
								<Switch
									label={tClientLabels('newClaim.prescription.prysm')}
									disabled={!editable}
									checked={Boolean(defaultData.prysm)}
									onChange={(_, checked) => {
										setData({ ...defaultData, prysm: Number(checked) });
									}}
								/>
								<Switch
									label={tClientLabels('newClaim.prescription.decentralization')}
									disabled={!editable}
									checked={Boolean(defaultData.decentralization)}
									onChange={(_, checked) => {
										setData({ ...defaultData, decentralization: Number(checked) });
									}}
								/>
							</Stack>
						)}
					</Stack>
					<PrescriptionTable
						isOldPrescription={isOldPrescription}
						presFilled={presFilled}
						editable={editable}
						data={defaultData}
						setData={newValue => {
							setData({ ...newValue });
						}}
						checkFields={checkFields}
						setRequiredFilled={setRequiredFilled}
						isContactOnly={isContactOnly}
					/>
				</Stack>
			</Stack>
		</>
	);
};

export default Prescription;
